import actions from '../../api/actions';

const newVersionVesselAction = {
	id: 'newVersionAction',
	selectionType: 'single',
	label: 'action.newversion',
	functionality: 'NEW_VERSION_MSHIPS',
	checkAvailability: function (registries) {
		var dataRegistry = registries[0];
		return (
			(dataRegistry && dataRegistry.inactiveind === 'No' && dataRegistry.shipstatusid != '1') ||
			(dataRegistry && dataRegistry.inactiveind === '0' && dataRegistry.shipstatusid != '1')
		);
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		const objectPk = actions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		row.fecininew = null;
		this.$puiEvents.$emit('pui-modalDialogForm-newVersion-' + model.name + '-show', row);
	}
};

const validatedShipAction = {
	id: 'validatedShipAction',
	selectionType: 'single',
	label: 'action.validatedship',
	functionality: 'VALIDATE_SHIPS',
	checkAvailability: function (row) {
		return (
			(row[0] && row[0].inactiveind === 'No' && row[0].shipstatusid != '3') ||
			(row[0] && row[0].inactiveind === '0' && row[0].shipstatusid != '3')
		);
	},
	runAction: function (action, model, registries) {
		const objectPk = actions.getPkFromModel(model, registries[0]);
		const row = {};
		row.headerPk = objectPk;
		row.isAction = true;
		row.shipid = registries[0].shipid;
		row.version = registries[0].version;
		this.$puiEvents.$emit('pui-modalDialogForm-validatedShipAction-' + model.name + '-show', row);
	}
};

const rejectedShipAction = {
	id: 'rejectedShipAction',
	selectionType: 'single',
	label: 'action.rejectedShip',
	functionality: 'REJECT_SHIPS',
	checkAvailability: function (row) {
		return (
			(row[0] && row[0].inactiveind === 'No' && row[0].shipstatusid != '2') ||
			(row[0] && row[0].inactiveind === '0' && row[0].shipstatusid != '2')
		);
	},
	runAction: function (action, model, registries) {
		const objectPk = actions.getPkFromModel(model, registries[0]);
		const row = {};

		row.headerPk = objectPk;
		row.isAction = true;

		row.shipid = registries[0].shipid;
		row.version = registries[0].version;
		row.rejectionreason = registries[0].rejectionreason;

		this.$puiEvents.$emit('pui-modalDialogForm-rejectedShipAction-' + model.name + '-show', row);
	}
};
/*
const notificationShipAction = {
	id: 'notificationShipAction',
	selectionType: 'single',
	label: 'action.notificationShip',
	functionality: 'REJECT_SHIPS',
	checkAvailability: function (row) {
		if (row[0] && row[0].inactiveind === '0' && row[0].shipstatusid != '2') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const objectPk = actions.getPkFromModel(model, registries[0]);
		const row = {};

		row.headerPk = objectPk;
		row.isAction = true;

		row.shipid = registries[0].shipid;
		row.version = registries[0].version;

		this.$puiEvents.$emit('pui-modalDialogForm-notificationShipAction-' + model.name + '-show', row);
	}
};
*/
export default {
	gridactions: [newVersionVesselAction, validatedShipAction, rejectedShipAction],
	formactions: [newVersionVesselAction, validatedShipAction, rejectedShipAction]
};
