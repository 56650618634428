<template>
	<div>
		<pui-datatable
			v-if="this.$store.getters.getSession.profiles.includes('HAMA') || this.$store.getters.getSession.profiles.includes('MTA')"
			:modelName="modelName"
			:actions="actions"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modelColumnDefs="modelColumnDefs"
			:readOnly="readOnly"
			:modalDialog="modalDialog"
			:showCreateBtn="false"
			:showDeleteBtn="false"
		>
		</pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modelColumnDefs="modelColumnDefs"
			:readOnly="readOnly"
			:modalDialog="modalDialog"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="false"
		>
		</pui-datatable>
		<MshipsModals :modelName="model"></MshipsModals>
	</div>
</template>

<script>
import mshipsActions from './MshipsActions';
import MshipsModals from './MshipsModals.vue';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'mships-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		MshipsModals: MshipsModals
	},
	data() {
		return {
			model: 'mships',
			modelName: 'mships',
			parentModelName: 'mships',
			actions: mshipsActions.gridactions,
			modelColumnDefs: {
				inivalidity: { withoutHours: true },
				endvalidity: { withoutHours: true },
				checkboxes: {
					render: (data) => {
						return this.returnStatusList(data);
					}
				},
				inactiveind: {
					render: (data) => {
						if (data === 'Yes') {
							return this.$t('imofalform3items.yes');
						} else if (data === 'No') {
							return this.$t('imofalform3items.no');
						} else {
							return '';
						}
					}
				}
			}
		};
	},
	methods: {
		extendFilter(filter) {
			if (this.parentModelName === 'portcalls') {
				filter.rules.push({ field: 'shipid', op: 'eq', data: this.parentModel.shipid });
			}
		},
		returnStatusList(data) {
			if (data !== null) {
				const items = data.split('|');

				let checkString = '';
				var counter = 0;
				items.forEach((item, index) => {
					const [letter, date] = item.split('#');
					let colorClass = '';

					var dateString = date === '' ? this.$t('grid.mships.statuslist.notexpired') : date;

					if (dateString !== this.$t('grid.mships.statuslist.notexpired')) {
						dateString = this.$dateTimeUtils.getLocalFormattedDateFromMillis(dateString, 'DD/MM/YYYY HH:mm');
					}

					switch (letter) {
						case 'P':
							colorClass = 'pendingStyle';
							break;
						case 'A':
							colorClass = 'attachedStyle';
							break;
						case 'E':
							colorClass = 'expiredStyle';
							break;
						default:
							colorClass = 'pendingStyle';
					}
					if (index == 0 && letter == 'E') {
						colorClass = 'attachedStyle';
					}
					var literal = this.$t('grid.mships.statuslist.' + counter);

					var title = literal + '\nExpiration date: ' + dateString;
					var letterTrans = this.$t('grid.mships.statuslist.letters.' + counter);
					const box = `<label  title="${title}" class="statusListChecks ${colorClass}">${letterTrans}</label>`;

					checkString += box;
					counter++;
				});
				return checkString;
			} else {
				return data;
			}
		}
	}
};
</script>
