<template>
	<div>
		<pui-modal-dialog-form
			:titleText="$t('action.newversion')"
			:modelName="modelName"
			:dialogName="dialogNameNewVersion"
			:widthDialog="800"
			:onOk="onOkNewVersion"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12 sm6 class="mt-4">
						<pui-date-field
							:id="`inivalidity${idSufix}`"
							:label="$t('mships.fecinivigoldLabel')"
							v-model="data.modalData.inivalidity"
							required
							toplabel
							disabled
						></pui-date-field>
					</v-flex>
					<v-flex xs12 sm6 class="mt-4">
						<pui-date-field
							:id="`fecininew${idSufix}`"
							:label="$t('mships.fecinivignewLabel')"
							v-model="data.modalData.fecininew"
							required
							toplabel
							:min="data.modalData.inivalidity"
						></pui-date-field>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:modelName="modelName"
			:cancelText="$t('nsw.cancel')"
			:okText="$t('nsw.accept')"
			:titleText="$t('validateship.titleText')"
			:dialogName="validatedShipAction"
			:onOk="onOkValidatedShip"
			><template slot="message">
				<v-container>{{ $t('validateship.text') }}</v-container>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:modelName="modelName"
			:cancelText="$t('nsw.cancel')"
			:okText="$t('nsw.accept')"
			:titleText="$t('action.rejectedShip')"
			:dialogName="rejectedShipAction"
			:widthDialog="900"
			:componentHeaderName="'mships-form-header'"
			:onOk="onOkRejectedShip"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12 sm12>
						<pui-text-area
							:id="`inivalidity${idSufix}`"
							:label="$t('modal.rejectedShipMessage')"
							v-model="data.modalData.rejectionreason"
							required
							toplabel
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('action.notificationShip')"
			:modelName="modelName"
			:dialogName="notificationShipAction"
			:onOk="onOknotificationShipAction"
			:widthDialog="900"
			:cancelText="$t('nsw.cancel')"
			:okText="$t('nsw.accept')"
		>
			<template slot="message" slot-scope="data">
				<div>
					<v-row class="mt-4">
						<v-col cols="6">
							<pui-select
								:id="`typenotification-${modelName}`"
								:label="$t('modal.typenotification')"
								toplabel
								clearable
								v-model="data.modalData"
								modelName=""
								:itemsToSelect="[{}]"
								:modelFormMapping="{}"
								itemValue=""
								itemText=""
								reactive
							></pui-select>
						</v-col>
						<v-col cols="6">
							<pui-select
								:id="`receiver-${modelName}`"
								:label="$t('modal.receiver')"
								toplabel
								clearable
								v-model="data.modalData"
								modelName=""
								:itemsToSelect="[{}]"
								:modelFormMapping="{}"
								:itemValue="['']"
								itemText=""
								reactive
							></pui-select>
						</v-col>
						<v-col cols="12">
							<pui-text-area
								:id="`mships-observations`"
								v-model="model.reciever"
								:label="$t('modal.observations')"
								:disabled="formDisabled"
								toplabel
								maxlength="256"
							></pui-text-area>
						</v-col>
					</v-row>
				</div>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>
<script>
export default {
	name: 'mships-modals',
	data() {
		return {
			dialogNameNewVersion: 'newVersion',
			showDialogValidatedShip: false,
			dialogNameValidatedShip: 'validatedShip',
			dialogNameRejectShip: 'rejectedShip',
			dialogNameNotificationShip: 'notificationShip',
			showDialogRejectedShip: false,
			model: 'mships',
			validatedShipAction: 'validatedShipAction',
			rejectedShipAction: 'rejectedShipAction',
			notificationShipAction: 'notificationShipAction',
			modalData: {},
			idSufix: `-${this.modelName}-modal`
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onOkNewVersion(modalData) {
			const params = {
				shipid: modalData.shipid,
				fecini: modalData.fecininew
			};
			const urlController = this.$store.getters.getModelByName(this.modelName).url.newVersion;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('action.newversion');
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					(response) => {
						const message = this.$puiI18n.t('puiaction.notifySuccess');

						const pk = this.$puiUtils.utf8ToB64(JSON.stringify(response.data));
						const params = {
							router: this.$router,
							url: `/${this.modelName}/update/${pk}/`,
							params: null,
							query: {},
							replace: true
						};
						this.$puiNotify.success(message, title);
						this.$store.dispatch('puiRouterGoToUrl', params);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiNotify.error(message, title, true);
						resolve(false);
					}
				);
			});
		},
		onOkValidatedShip(modalData) {
			const params = {
				shipid: modalData.shipid,
				version: modalData.version
			};
			const urlController = this.$store.getters.getModelByName(this.modelName).url.validatedship;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('action.validatedship');
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						this.showDialogValidatedShip = false;
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					}
				);
			});
		},
		onOkRejectedShip(modalData) {
			const params = {
				shipid: modalData.shipid,
				version: modalData.version,
				reason: modalData.rejectionreason
			};
			const urlController = this.$store.getters.getModelByName(this.modelName).url.rejectedship;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('action.rejectedShip');
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						this.showDialogRejectedShip = false;
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					}
				);
			});
		},

		onOknotificationShipAction(modalData) {
			const params = {
				shipid: modalData.shipid,
				version: modalData.version,
				reason: modalData.rejectionreason
			};
			const urlController = this.$store.getters.getModelByName(this.modelName).url.notificationship;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('action.notificationship');
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					urlController,
					params,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						this.showDialogRejectedShip = false;
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					}
				);
			});
		}
	}
};
</script>
